import React, { useEffect, useState } from "react";
import classes from "./WorkStages.module.scss";
import chevronDown from "../../media/icons/chevron-down.svg";
import chevronUp from "../../media/icons/chevron-up.svg";
import businessAnalysisImage from "../../media/work-stages/business-analysis.png";
import developmentImage from "../../media/work-stages/development.png";
import launchImage from "../../media/work-stages/launch.png";
import projectingImage from "../../media/work-stages/projecting.png";
import testingImage from "../../media/work-stages/testing.png";
import maintainingImage from "../../media/work-stages/maintaining.png";
import { useTranslation } from "react-i18next";
import {Link} from "react-scroll";

const WorkStages = () => {
  const {t, i18n} = useTranslation();

  const [openedBlock, setOpenedBlock] = useState(null);
  const [dropDownBlocks, setDropDownBlocks] = useState<
    { title: string; content: string[]; imageUrl: string }[] | null
  >([]);


  const openBlockHandler = (index) => {
    openedBlock === index ? setOpenedBlock(null) : setOpenedBlock(index);
  };
  useEffect(() => {
    setDropDownBlocks([
      {
        title:t('workStages.blocks.businessAnalysis.title'),
        content: [
          t('workStages.blocks.businessAnalysis.body.0'),
          t('workStages.blocks.businessAnalysis.body.1'),
        ],
        imageUrl: businessAnalysisImage,
      },
      {
        title:t('workStages.blocks.design.title'),
        content: [
          t('workStages.blocks.design.body.0'),
          t('workStages.blocks.design.body.1'),
        ],
        imageUrl: projectingImage,
      },
      {
        title:t('workStages.blocks.development.title'),
        content: [
          t('workStages.blocks.development.body.0'),
          t('workStages.blocks.development.body.1'),
        ],
        imageUrl: developmentImage,
      },
      {
        title:t('workStages.blocks.testing.title'),
        content: [
          t('workStages.blocks.testing.body.0'),
        ],
        imageUrl: testingImage,
      },
      {
        title:t('workStages.blocks.implementationAndLaunch.title'),
        content: [
          t('workStages.blocks.implementationAndLaunch.body.0'),
          t('workStages.blocks.implementationAndLaunch.body.1'),
        ],
        imageUrl: launchImage,
      },
      {
        title:t('workStages.blocks.supportAndDevelopment.title'),
        content: [
          t('workStages.blocks.supportAndDevelopment.body.0'),
        ],
        imageUrl: maintainingImage,
      },
    ]);
  }, [i18n.language]);

  return (
    <div className={classes["container"]}>
      <div className={classes["left"]}>
        <h2 className={classes["header"]}>{t('workStages.title')}</h2>
        <p className={classes["text"]}>
          {t('workStages.subtitle')}
        </p>
        <Link className={classes["connect-button"]} smooth={true} to="contact">
          {t('buttons.connect')}
        </Link>
      </div>

      <div className={classes["right"]}>
        <div className={classes["drop-down"]}>
          {dropDownBlocks.map((block, index) => (
            <div
              key={index}
              id={index.toString()}
              className={classes["drop-down__block"]}
            >
              <div
                className={classes["top"]}
                onClick={() => openBlockHandler(index)}
              >
                <div className={classes["drop-down__block__title"]}>
                  {block.title}
                </div>
                <img
                  src={openedBlock === index ? chevronUp : chevronDown}
                  alt="chevron"
                />
              </div>

              <div
                className={`${classes["drop-down__content"]} ${
                  openedBlock === index ? classes["visible"] : classes["hidden"]
                }`}
              >
                <img src={block.imageUrl} alt={block.title} />

                {block.content.map((text) => (
                  <p key={text}>{text}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkStages;
