import React from "react";
import classes from "./Heading.module.scss";
import checkmarkIcon from "../../media/icons/checkmark.svg";
import checkmarksCircleIcon from "../../media/icons/checkmarksCircle.svg";

const Checkmarks = () => {
  return (
    <div
      className={`${classes["heading__icon-container"]} ${classes["heading__row--5"]}`}
    >
      <img
        src={checkmarkIcon}
        alt="Checkmarks icon"
        className={classes["checkmarks-icon"]}
      />
      <img
        src={checkmarksCircleIcon}
        alt="Circle icon"
        className={classes["circle-icon"]}
      />
    </div>
  );
};

export default Checkmarks;
