import React from "react";
import classes from "./FormCard.module.scss";
import CardText from "./CardText.tsx";
import Form from "./Form.tsx";

const FormCard = () => {
  return (
    <div className={classes["form-card"]} id="contact">
      <CardText />
      <Form />
    </div>
  );
};

export default FormCard;
