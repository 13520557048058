import React from "react";
import classes from "./Partners.module.scss";

import agencyOfAutomobileRoadsLogo from "../../media/partners/agency-of-automobile-roads.svg";
import usaidLogo from "../../media/partners/usaid.png";
import kyivDigitalLogo from "../../media/partners/kyiv-digital.png";
import eastEuropeFundLogo from "../../media/partners/east-europe-fund.png";
import kyivContactCenterLogo from "../../media/partners/kyiv-contact-center.png";
import ukrzaliznytsyaLogo from "../../media/partners/ukrzaliznytsya.png";
import statePropertyFundLogo from "../../media/partners/state-property-fund.png";
import vcentriLogo from "../../media/partners/vcentri.png";
import transportSafetyServiceLogo from "../../media/partners/transport-safety-service.png";
import egapLogo from "../../media/partners/egap.png";

const Partners = () => {
  const partners = [
    {
      image: agencyOfAutomobileRoadsLogo,
      alt: "Agency of automobile roads logo",
    },
    { image: usaidLogo, alt: "USAID logo" },
    { image: kyivDigitalLogo, alt: "Kyiv digital дogo" },
    { image: eastEuropeFundLogo, alt: "East europe fund logo" },
    { image: kyivContactCenterLogo, alt: "Kyiv contact center logo" },
    { image: ukrzaliznytsyaLogo, alt: "Ukrzaliznytsya logo" },
    { image: statePropertyFundLogo, alt: "State property fund logo" },
    { image: vcentriLogo, alt: "Vcentri logo" },
    { image: transportSafetyServiceLogo, alt: "Transport safety service logo" },
    { image: egapLogo, alt: "Egap logo" },
  ];

  return (
    <div className={classes["partners-container"]}>
      {partners.map(({ image, alt }) => (
        <img
          className={classes["partner-image"]}
          key={image}
          src={image}
          alt={alt}
        />
      ))}
    </div>
  );
};

export default Partners;
