import React from "react";
import classes from "./Sectors.module.scss";
import { ISectorCard } from "./Sectors";

const SectorCard = ({
  image,
  title,
  text,
}: ISectorCard): React.FC<ISectorCard> => {
  return (
    <div className={classes["sector-card"]}>
      <div className={classes["sector-card__overflow"]}></div>
      <div className={classes["sector-card__image-container"]}>
        <img
          src={image}
          alt={title}
          className={classes["sector-card__image"]}
        />
      </div>
      <div className={classes["sector-card__text"]}>
        <h3 className={classes["sector-card__text--title"]}>{title}</h3>
        <p className={classes["sector-card__text--body"]}>{text}</p>
      </div>
    </div>
  );
};

export default SectorCard;
