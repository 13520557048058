import React from "react";
import classes from "./OurProjects.module.scss";
import { IProject } from "./OurProjects";
import rightArrowIcon from "../../media/icons/arrowRightBlack.svg";
import {useTranslation} from "react-i18next";

const ProjectItem = ({ imgUrl, title, description, url }): React.FC<IProject> => {
    const {t} = useTranslation()

  return (
    <div className={classes["project-item"]}>
      <div className={classes["project-image-container"]}>
        <img
          src={imgUrl}
          alt="Project image"
          className={classes["project-image"]}
        />
      </div>
      <h3 className={classes["project-title"]}>{title}</h3>
      <p className={classes["project-description"]}>{description}</p>
        {url && <a href={url} className={classes["project-link"]} target='_blank'>
            {t('buttons.learnMore')} <img src={rightArrowIcon} alt="Arrow icon"/>
        </a>}
    </div>
  );
};

export default ProjectItem;
