import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import eng from "./trans/eng.json";
import ua from "./trans/ua.json";

import i18n from "i18next";

const userLanguage = navigator.language;

const resources = {
  eng: {
    translation: eng,
  },
  ua: {
    translation: ua,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: userLanguage === "uk-UA" ? "ua" : "eng",
  });

export default i18n;
