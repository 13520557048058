import React, { useEffect, useRef, useState } from "react";
import classes from "./FormCard.module.scss";
import arrowRightBlack from "../../media/icons/arrowRightBlack.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice";
import validator from "validator";

interface Inputs {
  name: string;
  email: string;
  project: string;
}

const Form = () => {
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputs, setInputs] = useState<Inputs>({
    name: "",
    email: "",
    project: "",
  });

  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);

  const { t } = useTranslation();
  const labels: string[] = t("form.labels", { returnObjects: true });

  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof labels === "object") {
      dispatch(translationLoaded("form"));
    }
  }, []);

  const labelsInFirstRow: string[] = labels.slice(0, 2);
  const labelsInSecondRow: string[] = labels.slice(2);

  const selectLabel = (e) => {
    const label: string = e.currentTarget.textContent;
    if (!selectedLabels.includes(label)) {
      setSelectedLabels((prevLabels) => [...prevLabels, label]);
    } else {
      setSelectedLabels((prevLabels) =>
        prevLabels.filter((currentLabel) => currentLabel !== label),
      );
    }
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    let isValid = true;

    if (validator.isEmpty(inputs.name)) {
      if (nameInputRef)
        nameInputRef.current.parentElement?.classList.add("invalid");
      isValid = false;
    }
    if (!validator.isEmail(inputs.email)) {
      if (emailInputRef)
        emailInputRef.current.parentElement?.classList.add("invalid");
      isValid = false;
    }

    if (isValid) {
      const body = JSON.stringify({
        name: inputs.name,
        email: inputs.email,
        projectInfo: inputs.project,
        interests: selectedLabels,
      });
      setIsLoading(true);
      fetch("https://go-te.co:3001/", {
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json",
          mode: "no-cors",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            setInputs({ name: "", email: "", project: "" });
            setFormSubmitted(true);
            setSelectedLabels([]);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const changeInputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      nameInputRef.current?.classList.remove("invalid");
    }
    if (name === "email") {
      emailInputRef.current?.classList.remove("invalid");
    }

    setInputs({ ...inputs, [name]: value });
  };

  return (
    <form className={classes.form} onSubmit={submitFormHandler}>
      <div className={classes["form__inputs-container"]}>
        <div className={classes["form__input-container"]}>
          <input
            onChange={changeInputHandler}
            type="text"
            placeholder={t("form.inputs.name")}
            className={classes["form__input"]}
            ref={nameInputRef}
            name="name"
            value={inputs.name}
          />
        </div>

        <div className={classes["form__input-container"]}>
          <input
            onChange={changeInputHandler}
            type="text"
            placeholder={t("form.inputs.email")}
            className={`${classes["form__input"]} ${classes.invalid}`}
            ref={emailInputRef}
            name="email"
            value={inputs.email}
          />
        </div>

        <div className={classes["form__input-container"]}>
          <input
            onChange={changeInputHandler}
            type="text"
            placeholder={t("form.inputs.project")}
            className={classes["form__input"]}
            name="project"
            value={inputs.project}
          />
        </div>
      </div>
      <div className={classes["form__labels-container"]}>
        <p>{t("form.labelsHeader")}</p>
        <div className={classes["form__labels-container__rows-container"]}>
          <div className={classes["form__labels-container__row"]}>
            {typeof labelsInFirstRow === "object" &&
              labelsInFirstRow.map((label) => (
                <div
                  className={`${classes.label} ${
                    selectedLabels.includes(label) ? classes.selected : ""
                  }`}
                  id={label}
                  key={label}
                  onClick={selectLabel}
                >
                  {label}
                </div>
              ))}
          </div>
          <div className={classes["form__labels-container__row"]}>
            {typeof labelsInSecondRow === "object" &&
              labelsInSecondRow.map((label) => (
                <div
                  className={`${classes.label} ${
                    selectedLabels.includes(label) ? classes.selected : ""
                  }`}
                  id={label}
                  key={label}
                  onClick={selectLabel}
                >
                  {label}
                </div>
              ))}
          </div>
        </div>
      </div>
      <button
        className={`${classes["form__button"]} ${
          isLoading ? classes["form__button--sent"] : ""
        }`}
        type="submit"
        disabled={formSubmitted}
      >
        {!formSubmitted && !isLoading && t("form.button-text")}{" "}
        {formSubmitted && !isLoading && t("form.button-text-thanks")}
        {isLoading && (
          <>
            { t("form.button-text-sending")}
            <img src={arrowRightBlack} alt="Black arrow icon" />
          </>
        )}
      </button>
    </form>
  );
};

export default Form;
