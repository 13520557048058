import React, { useEffect } from "react";
import classes from "./FormCard.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice";
import arrowRightIcon from "../../media/icons/arrowRight.svg";

const CardText = () => {
  const { t } = useTranslation();
  const cardHeaderPieces: string[] = t("card-text.header", {
    returnObjects: true,
  });
  // todo show header after scrolling up

  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof cardHeaderPieces === "object") {
      dispatch(translationLoaded("cardText"));
    }
  }, []);

  return (
    <div className={classes["card-text-container"]}>
      <div className={classes["card-text-container__heading"]}>
        {typeof cardHeaderPieces === "object" &&
          cardHeaderPieces.map((text, index) => {
              const key = `${text}-${index}`;
            if (index === cardHeaderPieces.length - 1) {
                return (
                    <div key={key} className={classes["card-header"]}>
                        <span>{text}</span>
                        <div>
                            <img
                                className={classes["arrow-icon"]}
                                src={arrowRightIcon}
                                alt="Arrow to right"
                            />
                        </div>
                    </div>
                );
            }
            return <div key={key}>{text}</div>;
          })}
      </div>
      <div className={classes["card-text"]}>
        <p className={classes["card-text-container__body"]}>
          {t("card-text.body")}
        </p>
        <p className={classes["card-text-container__email"]}>info@go-te.co</p>
      </div>
    </div>
  );
};

export default CardText;
