import React, { useEffect } from "react";
import classes from "./AboutUs.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice";
import {Link} from "react-scroll";
import blueBackground from '../../media/about-us/blue-background.svg'

interface aboutUsItem {
  header: string[];
  body: string;
}

const AboutUs = () => {
  const { t } = useTranslation();
  const aboutUsBlocks: aboutUsItem[] = t("about-us.information", {
    returnObjects: true,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof aboutUsBlocks === "object") {
      dispatch(translationLoaded("aboutUs"));
    }
  }, []);

  return (
    <div className={classes["about-us-container"]} id="about-us">
      <div className={classes["heading-container"]}>
        <div className={classes["hover-background"]}>
          <Link className={classes["connect-button"]} smooth={true} to="contact">
            {t('buttons.connect')}
          </Link>
        </div>
        <span className={classes["heading-primary"]}>
          {t("about-us.header")}
        </span>
      </div>

      <div className={classes["blocks-container"]}>
        {typeof aboutUsBlocks === "object" &&
          aboutUsBlocks.map((item, index) => {
            return (
              <div
                className={`${classes.block} ${classes[`block--${index + 1}`]}`}
                key={item.body}
              >
                <img className={`${classes['block-background']} ${classes[`block-background--${index+1}`]}`} src={require(`../../media/about-us/block-background-${index+1}.svg`)} alt='block background'/>
                <div className={classes['block-background--blue-container']} >
                {/*<img className={`${classes['block-background--blue']}  ${classes[`block-background--blue--${index+1}`]}\``} src={require(`../../media/about-us/block-background-${index+1}-blue.svg`)} alt='block background'/>*/}
                <img className={`${classes['block-background--blue']}  ${classes[`block-background--blue--${index+1}`]}`} src={blueBackground} alt='block background'/>
                </div>
                <div className={classes["header-container"]}>
                  {typeof item.header === "object" &&
                    item.header.map((header) => (
                      <h2 className={classes["block-header"]} key={header}>
                        {header}
                      </h2>
                    ))}
                </div>
                <div className={classes["block-body"]}>{item.body}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AboutUs;
