import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../media/logo.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  if (!t) {
    return null;
  }
  return (
    <footer className={classes.footer}>
      <div>
        <img src={logo} alt="Logo" className={classes["footer__logo"]} />
      </div>

      <div className={classes["footer__company-info"]}>
        <div className={classes["footer__company-info__column"]}>
          <div className={classes["footer__company-info__row"]}>
            <div className={classes["footer__company-info--location"]}>
              {t("footer.ukraine-city")}
            </div>
            <div> {t("footer.ukraine-address")}</div>
          </div>
          <div
            className={`${classes["footer__company-info__row"]} ${classes["footer__company-info__row--numbers"]}`}
          >
            <div>01014</div>
            <div>+38 (044) 333 92 74</div>
          </div>
        </div>

        <div className={classes["footer__company-info__column"]}>
          <div className={classes["footer__company-info__row"]}>
            <div className={classes["footer__company-info--location"]}>
              USA Bruce Way
            </div>
            <div>North Highlands CA</div>
          </div>
          <div
            className={`${classes["footer__company-info__row"]} ${classes["footer__company-info__row--numbers"]}`}
          >
            <div>95660</div>
            <div>+1 (585) 445-1323</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
